export const spacing8 = '0.5rem'

export const spacing12 = '0.75rem'

export const spacing16 = '1rem'

export const spacing24 = '1.5rem'

export const spacing32 = '2rem'

export const spacing48 = '3rem'
