import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// styles
import { SolutionsTable, TableCell } from './style'

import Check from '../../assets/images/check.png'
import { laranja } from 'src/styles/colors'

type heroType = {
  oneLink: string;
}

const VantagensDaGestao = ({ oneLink }: heroType) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='bg-white py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='font-citrina fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-md-center mb-3'>
              Vantagens da Gestão de Cobranças do Inter
            </h2>
          </div>
        </div>
        <div className='col-12'>
          <SolutionsTable className='mt-5 mt-md-4'>
            <div className='tabShadow rounded-5' />
            <TableCell className='d-flex justify-content-between lineColor'>
              <div className='d-flex align-items-center'><p className='font-inter fs-14 lh-17 fs-md-18 lh-md-22 mb-0 fw-700' style={{ color: laranja.terra }}>Serviços</p></div>
              <div className='d-flex align-items-center justify-content-center'><p className='font-inter fs-14 lh-17 fs-md-18 lh-md-22 mb-0 fw-700 text-center' style={{ color: laranja.terra }}>Inter</p></div>
            </TableCell>

            <TableCell className='d-flex justify-content-between lineColor'>
              <div className='d-flex align-items-center'>
                <p className='font-inter fs-12 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Cobrança simples, parcelada e recorrente</p>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={Check} />
              </div>
            </TableCell>

            <TableCell className='d-flex justify-content-between lineColor'>
              <div className='d-flex align-items-center'>
                <p className='font-inter fs-12 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Múltiplas ferramentas para gestão: altere, duplique, arquive e cancele as suas cobranças</p>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={Check} />
              </div>
            </TableCell>

            <TableCell className='d-flex justify-content-between lineColor'>
              <div className='d-flex align-items-center'>
                <p className='font-inter fs-12 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Régua de cobranças automática</p>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={Check} />
              </div>
            </TableCell>

            <TableCell className='d-flex justify-content-between lineColor'>
              <div className='d-flex align-items-center'>
                <p className='font-inter fs-12 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Personalize as suas multas, juros e descontos</p>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={Check} />
              </div>
            </TableCell>

            <TableCell className='d-flex justify-content-between lineColor'>
              <div className='d-flex align-items-center'>
                <p className='font-inter fs-12 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Dashboard na home para melhor acompanhamento dos boletos</p>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={Check} />
              </div>
            </TableCell>

            <TableCell className='d-flex justify-content-between lineColor'>
              <div className='d-flex align-items-center'>
                <p className='font-inter fs-12 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Emissão de boletos via CNAB e API</p>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={Check} />
              </div>
            </TableCell>

            <TableCell className='d-flex justify-content-between lineColor'>
              <div className='d-flex align-items-center'>
                <p className='font-inter fs-12 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Recebimento via código de barras e pix</p>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={Check} />
              </div>
            </TableCell>
          </SolutionsTable>

          <p className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 font-inter fw-700 text-center mt-4 pt-2'>Todas essas possibilidades num só lugar, com muita agilidade, segurança e modernidade.</p>

          <div className='d-flex justify-content-md-center mt-4 pt-2'>
            <a
              href={oneLink}
              target='_blank'
              rel='noreferrer'
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none'
              title='Abrir conta PJ'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'Automatize a troca de arquivos com a segurança das VANs',
                  element_action: 'click button',
                  element_name: 'Abrir conta PJ',
                  redirect_url: oneLink,
                })
              }}
            >
              Abrir conta PJ
            </a>
          </div>

        </div>
      </div>
    </section>
  )
}

export default VantagensDaGestao
