import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { HeadLine } from './style'
import SectionComponent from 'src/components/SectionComponent'
import Picture from 'src/components/Picture'
import { sand } from 'src/styles/newColors'
import { Button } from '@interco/inter-ui/components/Button'

type BenefitsType = {
  oneLink: string;
}

const TEXT_CONTENT = {
  subtitle: 'Régua de cobrança:',
  title: 'Acompanhe de perto o pagamento de clientes',
  description: 'Tenha uma comunicação mais assertiva na cobrança dos seus clientes, evite inadimplências com mais facilidade e tenha clareza sobre o fluxo de pagamentos feitos para sua empresa.',
  cta: 'Abrir conta PJ',
}

const AcompanheDePerto = ({ oneLink }: BenefitsType) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      SectionStyles='d-flex align-items-center'
      bgColor={sand} id='acompanhe-de-perto-section'
      minHeight={{ xl: '668px', lg: '494px', md: '435px', sm: '711px' }}
    >
      <div className='col-12 col-md-6 mb-4 mb-md-0'>
        <Picture
          minHeight={{ xl: 'auto', lg: '361px', md: '266px', sm: '247px' }}
          width='100%'
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/gestao-de-cobranca-regua/image.webp'
          alt='Notebook na tela de Gestão de cobranças no Internet Banking Inter Empresas'
        />
      </div>
      <div className='col-12 col-md-6'>
        <h2 className='font-citrina fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-48 lh-xl-56 fw-600 text-grayscale--500 mb-4 mb-md-4'>
          <HeadLine className='fs-16 lh-20 fw-600 mb-1'>{TEXT_CONTENT.subtitle}</HeadLine><br />
          {TEXT_CONTENT.title}
        </h2>
        <p className='font-inter fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--400 mb-4'>
          {TEXT_CONTENT.description}
        </p>

        <Button
          as='a'
          href={oneLink}
          fullWidth
          target='_blank'
          rel='noreferrer'
          className='mw-100'
          title={TEXT_CONTENT.cta}
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_05',
              section_name: TEXT_CONTENT.title,
              element_action: 'click button',
              element_name: TEXT_CONTENT.cta,
              redirect_url: oneLink,
            })
          }}
        >
          {TEXT_CONTENT.cta}
        </Button>

      </div>
    </SectionComponent>
  )
}

export default AcompanheDePerto
