import React from 'react'

// Data
import GarantaBoletosJSON from '../../assets/data/garanta-boletos.json'

// styles
import { CardAdvantages, Advantages, TitleWrapper } from './style'
import SectionComponent from 'src/components/SectionComponent'

type secureYourCardType = {
  text: string;
  description: string;
}

const TEXT_CONTENT = {
  heading: 'Garanta boletos gratuitos todo mês',
  body: 'No Inter Empresas, seu negócio ganha mais economia na hora de gerenciar cobranças e emitir boletos à vista, parcelados ou recorrentes',
}

const GarantaBoletos = () => (
  <SectionComponent
    id='garanta-boletos-gratuitos'
    RowStyles='align-items-lg-center'
    bgColor='#FDF8EE'
    minHeight={{ sm: '885px', md: '535px', lg: '425px', xl: '600px' }}
  >
    <TitleWrapper className='section_component__title_wrapper col-12 col-lg-5 col-xl-5'>
      <h2 className='section_component__title_wrapper__title fs-24 lh-28 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-60 fw-600 text-orange--extra mb-4 text-center text-lg-left'>
        {TEXT_CONTENT.heading}
      </h2>
      <p className='section_component__title_wrapper__description fs-16 lh-20  text-grayscale--400 text-center text-lg-left mb-5'>{TEXT_CONTENT.body}</p>
    </TitleWrapper>

    <div className='col-lg-7 col-xl-7'>
      <Advantages>
        {GarantaBoletosJSON.map((item: secureYourCardType) => (
          <div key={item.text} className='d-flex mb-3 mb-md-0'>
            <CardAdvantages className='bg-white rounded-3 flex-md-column w-100'>
              <div>
                <h3 className='fs-16 lh-20 fw-700 fs-xl-20 lh-xl-24 text-grayscale--500 mb-2 mb-md-2 font-citrina' dangerouslySetInnerHTML={{ __html: item.text }} />
                <p className='fs-14 lh-17 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0'>{item.description}</p>
              </div>
            </CardAdvantages>
          </div>
        ))}
      </Advantages>
    </div>
  </SectionComponent>
)

export default GarantaBoletos
